import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Params } from '@angular/router';
import { GroundTimeChangeType, GroundTimeResponseDto } from '../settings.models';
import { AirportFilterOptions } from '../airports/airports.model';
import { DropdownHelper } from '@shared/helpers/dropdown-helper';
import { ConfigEndpoint } from '@shared/services/config-endpoint-decorator';
import { SeasonCode } from '@shared/models/seasons.model';
import { GridCell } from '@shared/services/grid-select.service';
import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
@ConfigEndpoint({ version: 2 })
export class SettingsHttpService {
  private rootEndPoint = 'groundtimes';
  private apiUrl: string;

  constructor(private http: HttpClient) {}

  getGroundTimes(filterOptions: AirportFilterOptions, season: SeasonCode): Observable<GroundTimeResponseDto> {
    const queryParams = DropdownHelper.getFilterOptionsQueryParams(filterOptions, season);
    return this.http.get<GroundTimeResponseDto>(`${this.apiUrl}/${season}/${this.rootEndPoint}?${queryParams}`, {
      responseType: 'json'
    });
  }

  saveGroundTimeOverride(
    changes: GridCell[],
    length: number | null,
    type: GroundTimeChangeType,
    season: SeasonCode
  ): Observable<void> {
    const params: Params = { changes, length, type };
    return this.http.post<void>(`${this.apiUrl}/${season}/${this.rootEndPoint}`, params);
  }

  clearCache(): Observable<void> {
    return this.http.get<void>(`${environment.javaApiUrl}/cache/evict`);
  }
}
